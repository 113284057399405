import React, { useEffect } from 'react'
import '../styles/header.css'
import { useState } from 'react'
import Sidebar from './Sidebar'
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import { useRef } from 'react';
import { Link } from 'react-router-dom';


function Header() {
  const [isScrolled, setIsScrolled] = useState(false)
  const[isSidebarOpen, setIsSidebarOpen] = React.useState(false)
  const divRef = useRef(null);

  

  // Handler when page is scrolled
  function handleScroll() {
    if(window.scrollY > 40) {
      setIsScrolled(true)
      console.log('scrolled')
    }
    if(window.scrollY <20) {
      setIsScrolled(false)
      console.log('not scrolled')
    }
  }

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsSidebarOpen(false)
    }
  };

  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const changeLanguage = (language) => {
    sessionStorage.setItem('language', language)
    window.location.reload()
  }

  const textEnglish = [{
    title: 'Home'
},
{

    title: 'About'
},
{
    title: 'Gallery'
}
]

const textSerbian = [{
    title: 'Početna'
},
{

    title: 'O nama'
},
{
    title: 'Galerija'
}
];

var language = sessionStorage.getItem('language');


  window.addEventListener('scroll', handleScroll);
  window.addEventListener('mousedown', handleClickOutside);

  return (
    <div className="header-div">
      <div className={isSidebarOpen ? "" : "hidden"} onClick={toggleSidebar}>
          <div ref={divRef}>
            <Sidebar />
          </div>
      </div>
    <div className={isScrolled? 'header-scrolled':'header'}>
    <MenuSharpIcon className='menu-icon' onClick={toggleSidebar}/>
      <div className='logo'>
        <Link to="/">
          <img src={require('../images/logo drawn cropped.png')} alt='logo' />
        </Link>
      </div>
      <div className='menu'>
        <ul className='menu-list'>
          <li><Link to='/'>
            {language==="english"?textEnglish[0].title:textSerbian[0].title}
            </Link></li>
          <li><Link to='/about'>
            {language==="english"?textEnglish[1].title:textSerbian[1].title}
            </Link></li>
          <li><Link to='/gallery'>
            {language==="english"?textEnglish[2].title:textSerbian[2].title}
            </Link></li>
        </ul>
        
      </div>
      
      <div className='title'>
        <h1>TRAIAN</h1>
      </div>

      <div className='language'>
        <button className='language-button' onClick={()=>changeLanguage("english")}>EN</button>
        <button className='language-button' onClick={()=>changeLanguage("srpski")}>SR</button>
      </div>
    </div>
    </div>
  )
}

export default Header