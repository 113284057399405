import React from 'react'
import '../styles/Sidebar.css'
import { Link } from 'react-router-dom';

function Sidebar() {

  const textEnglish = [
    {
      title: 'HOME'
    },
    {
      title: 'ABOUT'
    },
    {
      title: 'GALLERY'
    }
  ]

  const textSerbian = [
    {
      title: 'POČETNA'
    },
    {
      title: 'O NAMA'
    },
    {
      title: 'GALERIJA'
    }
  ]

  var language = sessionStorage.getItem('language');

  return (
    <div className='sidebar'>
        <nav>
              <div className='links'>
                <Link to='/' >
                  {language==="english"?textEnglish[0].title:textSerbian[0].title}
                </Link>
                <Link to='/about'>
                  {language==="english"?textEnglish[1].title:textSerbian[1].title}
                </Link>
                <Link to='/gallery'>
                  {language==="english"?textEnglish[2].title:textSerbian[2].title}
                </Link>
              </div>
        </nav>
    </div>
  )
}

export default Sidebar