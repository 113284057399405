
import React from 'react';
import '../styles/CTA.css';

function CallToAction() {

    const textEnglish = [{
        title: 'Let\'s build something beautiful together'
    },
    {
        title: 'Our team of expert designers and craftsmen is ready to bring your vision to life.',
        text: ''
    }
    ,{
        title: 'Get in touch'
    }
]

const textSerbian = [{
    title: 'Izgradimo nešto lepo zajedno'
    },
{
    title: 'Naš tim stručnih dizajnera i zanatlija je spreman da oživi Vašu viziju.',
    text: ''
},
{
    title: 'Kontaktirajte nas'
}
];

var language = sessionStorage.getItem('language');

    function handleClick() {
        if (language === 'english') {
            window.location.href = 'mailto:contact@traian.art?subject=Traian Art Inquiry&body=Hello, I am interested in learning more about your services. Please contact me at your earliest convenience. Thank you.';
        }else{
            window.location.href = 'mailto:kontakt@traian.art?subject=Traian Art Upit&body=Zdravo, zainteresovan sam za Vaše usluge. Molim Vas da me kontaktirate što pre. Hvala.';
        }
    }

    return (
        <div className='cta'>
            <div className='cta-text'>
                <h1>
                    {language==="english"?textEnglish[0].title:textSerbian[0].title}
                </h1>
                <p>
                    {language==="english"?textEnglish[1].title:textSerbian[1].title}
                </p>
            </div>
            <div className='cta-button-div'>
                <button class="cta-button" role="button" onClick={()=>handleClick()}><span class="text">
                    {language==="english"?textEnglish[2].title:textSerbian[2].title}
                    </span></button>
            </div>
        </div>
    );
}

export default CallToAction;
