import React from 'react'
import '../styles/footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { Link } from 'react-router-dom';


function Footer() {
    var language = sessionStorage.getItem('language');

    const textEnglish = {
        home: 'Home',
        about: 'About',
        gallery: 'Gallery',
        email: 'contact@traian.art'
    }

    const textSrpski = {
        home: 'Početna',
        about: 'O nama',
        gallery: 'Galerija',
        email: 'kontakt@traian.art'
    }

    const text = language === 'srpski' ? textSrpski : textEnglish

  return (
    <div className='footer'>
        <div className='footer-links'>
            <Link to='/'>{text.home}</Link>
            <Link to='/about'>{text.about}</Link>
            <Link to='/gallery'>{text.gallery}</Link>
        </div>
        <div className='footer-social'>
            <a href='https://www.instagram.com/traian.arts/'><InstagramIcon className='icon'/></a>
        </div>
        <div className='footer-contact'>
            <p>
                {text.email}
            </p>
        </div>

    </div>
  )
}

export default Footer