import React from 'react'
import '../../styles/About.css'

import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from "react-lazy-load-image-component";

import ImageOne from '../../images/About/1.png'
import { useLayoutEffect } from 'react';

function About() {

    const textEnglish = [{
        title: 'Who Are We?'
    },
    {
        title: 'Synthesizing Heritage with Modern Elegance',
        text: 'Welcome to Traian, a realm where the strength of iron meets the delicacy of artisanal mastery to create lasting symbols of sophistication. Nestled in the verdant landscapes of Belgrade, a place where traditions stand as strong as the iron shaped in our workshops, Traian represents a harmonious fusion of historic iron forging methods with innovative, contemporary designs. Here, wrought iron transcends its physical form, becoming a narrative of heritage, exceptional artisanship, and timeless grace.'
    },
    {
        title: 'Our Odyssey',
        text: 'Starting more than 20 years ago, Traian embarked on a mission to revolutionize the world of wrought iron craftsmanship. Our journey has been a testament to precision, passion, and unparalleled skill. Named after the founder\'s father, as well as the legendary Roman Emperor Traian, our brand symbolizes the strength, grandeur, and enduring quality of our wrought iron masterpieces, reminiscent of the timeless architectural wonders from that historical epoch.'
    }
]

const textSerbian = [{
    title: 'Ko smo mi?'
    },
{
    title: 'Spoj nasleđa sa modernom elegancijom',
    text: 'Dobrodošli u Traian, gde se susreću snaga gvožđa i delikatnost zanatske veštine kako bi se stvorili trajni simboli sofisticiranosti. Mesto gde tradicije stoje jednako čvrsto kao gvožđe oblikovano u našim radionicama. Traian predstavlja harmonično spajanje istorijskih metoda kovanja gvožđa sa inovativnim, savremenim dizajnom. Ovde, kovanje gvožđa prevazilazi svoj fizički oblik, postajući priča o nasleđu, izuzetnom zanatstvu i bezvremenskoj eleganciji.'
},
{
    title: 'Naš Put',
    text: 'Počevši pre više od 20 godina, Traian je krenuo na misiju da usavrši svet veštine kovanja gvožđa. Naš put su svedočanstvo preciznosti, strasti i neuporedive veštine. Imenovan po ocu osnivača, kao i legendarnom rimskom caru Trajanu, naš brend simbolizuje snagu, veličanstvenost i trajan kvalitet naših remek-dela od kovanog gvožđa, podsećajući na arhitektonska čuda iz tog istorijskog doba.'
}
];

var language = sessionStorage.getItem('language');

useLayoutEffect(() => {
    window.scrollTo(0, 0)
});


  return (
<div className='container'>
    <div className='who-container'>
        <div className='who-text'>
            <h1>{language==="english"?textEnglish[0].title:textSerbian[0].title}</h1>
        </div>
        <div className='who-image'>
            <LazyLoadImage effect='blur' src={ImageOne} alt='Traian identity'/>
        </div>
    </div>
    <div className='about-container-1'>
        <div className='about-image'>
            <LazyLoadImage effect='blur' src={require('../../images/About/2.jpg')} alt='Traian legacy'/>
        </div>
        <div className='about-text-container'>
            <h1> {language==="english"?textEnglish[1].title:textSerbian[1].title} </h1>
            <p>
            {language==="english"?textEnglish[1].text:textSerbian[1].text}
            </p>
        </div>
    </div>
    <div className='about-container-2'>
        <div className='about-text-container'>
            <h1>
            {language==="english"?textEnglish[2].title:textSerbian[2].title}
            </h1>
            <p>
                {language==="english"?textEnglish[2].text:textSerbian[2].text}
            </p>
        </div>
        <div className='about-image'>
            <LazyLoadImage effect='blur' src={require('../../images/About/3.png')} alt='Traian journey'/>
        </div>
    </div>
</div>

  )
}

export default About