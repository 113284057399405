import { Dialog, DialogContent } from '@mui/material'
import React from 'react'



function PictureDialog({open, handleClose,src,text}) {
    if(!src) {
        return
        }
  return (
    <Dialog fullWidth style={{padding:"0",backgroundColor:"rgba(0,0,0,0.1)",height:"100%",backdropFilter:"blur(10px)"}} open={open} onClose={handleClose}>
        <DialogContent style={{padding:"0",background:"transparent"}} >
            <img style={{padding:"0",background:"transparent"}}  src={src}  />
        </DialogContent>

    </Dialog>
  )
}

export default PictureDialog