import React from 'react';
import './App.css';
import Header from './components/Header';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/english/Home';
import About from './pages/english/About';
import Footer from './components/Footer';
import Gallery from './pages/english/Gallery';
import './styles/Sidebar.css'
import CallToAction from './components/CallToAction';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {
  ReactGA.initialize('G-QBRGGJHTEX');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  

  if(sessionStorage.getItem('language') === null) {
    sessionStorage.setItem('language', 'srpski')
  }

  


  return (

    
    <div className="App">
      <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        <CallToAction />
      <Footer />
    </div>
  );
}

export default App;
