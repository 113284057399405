import React from 'react'
import '../../styles/Gallery.css'
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PictureDialog from '../../components/dialog/PictureDialog';

import { useLayoutEffect } from 'react';


function Gallery() {
  const [item, setItem] = React.useState('')
  const [open, setOpen] = React.useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  const imagesGates = require.context('../../images/Gallery/Gates', true);

  const itemsGates = imagesGates.keys().map((item, index) => {
    return (
      <ImageListItem key={index} className='gallery-image-div'>
        <img
          onClick={() => handleOpen({ img: imagesGates(item), title: item.split('/')[1] })}
          className='gallery-image'
          src={imagesGates(item)}
          alt={item.split('/')[1]}
        />
        
      </ImageListItem>
    )
  })

  const imagesRailings = require.context('../../images/Gallery/Railings', true);

  const itemsRailings = imagesRailings.keys().map((item, index) => {
    return (
      <ImageListItem key={index} className='gallery-image-div'>
        <img
          onClick={() => handleOpen({ img: imagesRailings(item), title: item.split('/')[1] })}
          className='gallery-image'
          src={imagesRailings(item)}
          alt={item.split('/')[1]}
        />
        
      </ImageListItem>
    )
  })

  const imagesDesigns = require.context('../../images/Gallery/Designs', true);

  const itemsDesigns = imagesDesigns.keys().map((item, index) => {
    return (
      <ImageListItem key={index} className='gallery-image-div'>
        <img
          onClick={() => handleOpen({ img: imagesDesigns(item), title: item.split('/')[1] })}
          className='gallery-image'
          src={imagesDesigns(item)}
          alt={item.split('/')[1]}
        />
      </ImageListItem>
    )
  })


  const textEnglish = [
    {
      title: 'Gallery'
    },
    {
      title: 'Gates'
    },
    {
      title: 'Railings'
    },
    {
      title: 'Designs'
    }
  ]

  const textSerbian = [
    {
      title: 'Galerija'
    },
    {
      title: 'Kapije'
    },
    {
      title: 'Ograde'
    },
    {
      title: 'Dizajn'
    }
  ]


  const language = sessionStorage.getItem('language')



  const handleOpen = (item) => {
    setItem(item)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div className='gallery'>
      <h1 className='gallery-title'>
        {language === "english" ? textEnglish[0].title : textSerbian[0].title}
      </h1>
      <div className='gallery-div' id='gates'>
      <h2 className='gallery-subtitle'>
        {language === "english" ? textEnglish[1].title : textSerbian[1].title}
      </h2>
        <Box sx={{ width: '90%', height: '100%' }}>
          <ImageList variant="masonry"    sx={{
                  columnCount: {
                    xs: '1 !important',
                    sm: '2 !important',
                    md: '2 !important',
                    lg: '3 !important',
                    xl: '3 !important',
                  },
                }} gap={8}>
            {itemsGates}
          </ImageList>
        </Box>
      
        
        </div>
        <div className='gallery-div' id='railings'>
        <h2 className='gallery-subtitle'>
        {language === "english" ? textEnglish[2].title : textSerbian[2].title}
        </h2>
        <Box sx={{ width: '90%', height: '100%' }}>
          <ImageList variant="masonry"    sx={{
                  columnCount: {
                    xs: '1 !important',
                    sm: '2 !important',
                    md: '2 !important',
                    lg: '3 !important',
                    xl: '3 !important',
                  },
                }} gap={8}>
            {itemsRailings}
          </ImageList>
        </Box>
        </div>
        <div className='gallery-div' id='designs'>
          <h2 className='gallery-subtitle'>
          {language === "english" ? textEnglish[3].title : textSerbian[3].title}
          </h2>

        <Box sx={{ width: '90%', height: '100%' }}>
          <ImageList variant="masonry"    sx={{
                  columnCount: {
                    xs: '1 !important',
                    sm: '2 !important',
                    md: '2 !important',
                    lg: '3 !important',
                    xl: '3 !important',
                  },
                }} gap={8}>
            {itemsDesigns}
          </ImageList>
        </Box>
        <PictureDialog open={open} handleClose={handleClose} src={item.img} text={item.title}/>
        </div>
        
    </div>
  )


}

export default Gallery